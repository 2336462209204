@import "~animate.css/source/_base.css";
@import "~animate.css/source/fading_entrances/fadeIn.css";
@import "~animate.css/source/fading_exits/fadeOut.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  width: 100%;
  height: 100%;
}

html {
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
}

.player_holder
{
  background: rgb(0, 0, 0);
  height: 100%;
  max-height: 100%;
}

.receipt_holder
{
  background: rgb(0, 0, 0);
  text-align: center;
  opacity: 0;
  animation-delay: 3s;
}
.receipt_img
{
  width: 100%;
  max-width: 800px;
  height: auto;
}
