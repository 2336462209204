.qzp_quickZingPlayerHolder
{
    width: 100%;
    height: 100%;
    position: relative;
}

.qzp_quickZingPlayerLoadingCover
{
    position: absolute;
    width: 100%;
    height: calc(100% - 44px);
    left: 0;
    top: 0;
    background: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-family: sans-serif;
    animation-duration: .3s;
    pointer-events: none;
}
.qzp_quickZingPlayerLoadingCover div
{
    background: black;
    position: relative;
    top: -11px;
    line-height: 30px;
}

.qzp_quickZingPlayer
{

      width: 100%;
      height: 1px;
      min-height: calc(100% - 44px);
      border: none;
      overflow: hidden;
      
    
}

@keyframes qzp_buttons_fadein
{
    from { opacity: 0 }
    to { opacity: 0.6 }
}

.qzp_buttons
{
    position :absolute;
    width :100%;
    height: 44px;
    background: none;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: visible;
    animation: qzp_buttons_fadein;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.qzp_play_button
{
    width: 40px;
    height: 44px;
    background-image: url( ./img/play_white_30x44.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    top: 0;
}

.qzp_pause_button
{
    width: 40px;
    height: 44px;
    background-image: url( ./img/pause_white_30x44.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    top: 0;
}



.qzp_replay_button
{
    width: 40px;
    height: 44px;
    background-image: url( ./img/replay_white_30x44.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 0;
}